import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import ProjectsSection from "./components/ProjectsSection";
import AboutMeSection from "./components/AboutMeSection";
import Footer from "./components/Footer";
import ScrollToTop from "react-scroll-to-top";


export default function App() {
    return (
        <>
            <Header />
            <main>
                <HeroSection />
                <ProjectsSection />
                <AboutMeSection />
            </main>
            <Footer />
            <ScrollToTop 
                style={{borderRadius: "50%", width: "50px", height: "50px" }}
                svgPath="M374.6 246.6C368.4 252.9 360.2 256 352 256s-16.38-3.125-22.62-9.375L224 141.3V448c0 17.69-14.33 31.1-31.1 31.1S160 465.7 160 448V141.3L54.63 246.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160C387.1 213.9 387.1 234.1 374.6 246.6z"
                viewBox="0 0 384 512"
            />
        </>
    );

}